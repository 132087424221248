<template>
  <div class="container">
    <header-back name='团队管理'>
      <!-- 4级代理不能添加账号 -->
      <template
        slot="seachRight"
        v-if="userType < 4"
      >
        <span @click="onRight"> 添加账号</span>
      </template>
    </header-back>
    <section class="top44">
      <van-tabs
        v-model="activeName"
        @click="onTabClick"
      >
        <van-tab
          title="我的团队"
          name="1"
        >
          <div class="team-sta-cen">
            <van-field
              name="radio"
              label="账户状态："
            >
              <template #input>
                <van-radio-group
                  v-model="radio"
                  direction="horizontal"
                  @change='radioChange'
                >
                  <van-radio
                    icon-size="16px"
                    checked-color="#94FF75"
                    v-for="(item,index) in radioList"
                    :key='index'
                    :name="item.name"
                  >{{item.value}}</van-radio>

                </van-radio-group>
              </template>
            </van-field>
          </div>
          <div class="team-cen">

            <van-search
              v-model="seachValue"
              shape="round"
              background="transparent"
              placeholder="输入名称 / 账号"
              @search='onSeach'
            />
            <div class="team1">
              <van-pull-refresh
                v-model="refreshing"
                @refresh="onRefresh"
              >
                <van-list
                  v-model="loading"
                  :finished="finished"
                  :offset='offset'
                  finished-text="没有更多了"
                  @load="onLoad"
                >
                  <div class="van-clearfix">
                    <div
                      class="teamc"
                      v-for="(item,index) in teamList"
                      :key='index'
                    >
                      <div class="temList">
                        <div class="team-t">
                          <div class="team-tl">账号：{{item.username}}</div>
                          <div class="team-tr">
                            <van-switch
                              v-model="item.checked"
                              active-color="#1AFA29"
                              size="22px"
                              @change="onSwitchChange($event,item)"
                            />
                          </div>
                        </div>
                        <div class="team-h">
                          <div class="team-hl">
                            <div class="team-hl-img">
                              <van-image
                                round
                                width="42"
                                height="42"
                                :src="item.avatar"
                              />

                            </div>
                            <div class="team-hl-tit">
                              <div>姓名：{{item.nickname}}</div>
                              <div>工号：{{item.username}}</div>
                              <div>类型：<span v-if="item.userLevel !==4">{{item.userLevel}}级代理商</span><span v-else>操作员</span> </div>
                            </div>
                          </div>
                          <div
                            class="team-hr"
                            style="cursor: pointer;"
                          >
                            <div @click="editDiscount(item)">修改折扣
                              <van-icon name="arrow" />
                            </div>
                            <div
                              style="margin-top:15px;cursor: pointer;"
                              @click="editPaw(item)"
                            >修改密码
                              <van-icon name="arrow" />
                            </div>
                          </div>
                        </div>
                        <div class="team-f">
                          <div class="team-item">今日收益：<span class="red-color">{{item.todayIncome}}</span></div>
                          <div class="team-item">昨日收益：<span class="red-color">{{item.yesterdayIncome}}</span></div>
                          <div class="team-item">总收益：<span class="red-color">{{item.sumIncome}}</span></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </van-list>
              </van-pull-refresh>
            </div>
          </div>
        </van-tab>
        <van-tab
          title="批量设置"
          name="2"
        >
          <div class="team-cen">
            <van-search
              v-model="seachValue2"
              shape="round"
              background="transparent"
              placeholder="输入产品名称"
              @search='onSeach2'
            />
            <div class="team2">
              <div class="team2-h">

                <!-- <div
                  class="team2-ht"
                  :class="{'htActive':tagIndex===index}"
                  v-for="(item,index) in tagList"
                  :key='index'
                  @click="tagCLick(index)"
                >{{item.name}}</div> -->

              </div>
              <div class="team2-m">
                <div class="team2-head">

                  <div
                    class="team2-th"
                    v-for="(item,index) in head"
                    :key='index'
                  >{{item}}</div>

                </div>
                <div
                  class="team2-m-cen"
                  v-if="headList.length"
                >

                  <div
                    class="team2-list"
                    v-for="(item,index) in headList"
                    :key='index'
                  >
                    <div class="team2-list-name">{{item.discountName}}</div>
                    <div class="team2-list-def"> <span>{{item.userDiscountAmount}} %</span></div>
                    <div class="team2-list-dic">
                      <van-cell-group v-if="userType < 3">
                        <van-field
                          type="digit"
                          v-model="item.childUserDiscountAmount"
                          placeholder="请输入"
                          :border="false"
                          class="tem2-input1"
                          @input='onInput($event,item)'
                        >
                          <template #right-icon>
                            <span>%</span>
                          </template>
                        </van-field>
                      </van-cell-group>
                      <span v-else>{{item.childUserDiscountAmount}}</span>
                    </div>
                    <div class="team2-list-btn">
                      <van-switch
                        v-model="item.checked"
                        active-color="#1AFA29"
                        size="16px"
                        @change="onHeadhSwitchChange($event,item)"
                      />
                      <!-- <van-button
                        type="primary"
                        round
                        :color="variables.textColor"
                        @click="onSaveClick"
                        size="mini"
                      >保存</van-button> -->
                    </div>
                  </div>

                </div>
                <div
                  class="team2-m-cen"
                  v-else
                >
                  <p style="text-align: center;padding:20px;color:#999;font-size:12px">暂无数据</p>

                </div>
                <div class="team2-btn">
                  <van-button
                    :color="variables.textColor"
                    size="small"
                    round
                    type="info"
                    @click="saveClick"
                  >批量保存</van-button>
                </div>
              </div>
            </div>
          </div>
        </van-tab>

      </van-tabs>
    </section>
    <van-popup
      v-model="pwdShow"
      @close='onPwdClose'
      :style="{ width: '70%' }"
    >
      <div
        class="wrappergg"
        @click.stop
      >
        <div class="blockPwd">
          <div class="box">
            <div style="font-size:14px;padding:15px">修改账号 <span style="color:red">{{userTitle}}</span> 的密码</div>
            <van-form @submit="onPwdSubmit">

              <van-field
                v-model="reNewP"
                name="reNewP"
                label=""
                placeholder="请输入密码"
              />

              <div class="user-btn">
                <van-button
                  round
                  type="info"
                  size="small"
                  block
                  :color="variables.textColor"
                  native-type="submit"
                >提 交</van-button>
              </div>
            </van-form>
          </div>
        </div>
      </div>
    </van-popup>

  </div>

</template>
<script>
import HeaderBack from "../../components/header-back";
import { Toast } from "vant";
import utils from "../../utils/index";
import { request } from "http";
import variables from "@/styles/variables.scss";
import API from "../../api/http";
export default {
  components: { HeaderBack },
  data() {
    return {
      activeName: "1",
      seachValue: "",
      seachValue2: "",
      teamList: [],
      tagIndex: 0,
      radio: "0",
      radioList: [
        {
          value: "全部",
          name: "0",
        },
        {
          value: "可用",
          name: "1",
        },
        {
          value: "禁用",
          name: "2",
        },
      ],
      tagList: [
        {
          name: "默认注册折扣",
          type: "1",
        },
        {
          name: "批量设置折扣",
          type: "2",
        },
      ],
      head: [],
      head1: ["产品", "我的折扣", "代理折扣", "状态"], //保存折扣 改成 状态
      head2: ["产品", "我的折扣", "代理折扣", "状态"],
      headList: [
        {
          name: "实物A",
          id: "1",
          discount: "0.00",
          discountDef: "0.5",
          checked: true,
        },
        {
          name: "实物B",
          id: "1",
          discount: "0.5",
          discountDef: "0.00",
          checked: true,
        },
      ],
      loading: false,
      finished: false,
      refreshing: false,
      offset: 60,
      listQuery: {
        page: 1,
        status: 0,
      },
      oldHeadList: [], //保存列表（代理折扣）数据
      copyHeadList: [],
      pwdShow: false,
      userId: "",
      reNewP: "",
      userTitle: "",
    };
  },
  computed: {
    variables() {
      return variables;
    },
    userType() {
      return this.$store.getters["user/userType"];
    },
  },
  created() {
    this.head = this.head1;
    this.activeName = this.$route.query.active;
  },
  methods: {
    getInit(val) {
      API.getTeamList(this.listQuery)
        .then((res) => {
          let data = res.data;
          this.loading = false;
          this.seachNum = data.total;
          if (data.list.length > 0) {
            let list = data.list.map((item) => {
              return {
                ...item,
                checked: item.status === 0 ? true : false,
              };
            });

            if (data.pages === 1) {
              this.finished = true;
            }
            if (val) {
              this.refreshing = false;

              this.teamList = list;
            } else {
              this.teamList = this.teamList.concat(list);
            }

            this.listQuery.page++;
          } else {
            this.finished = true;
          }
        })
        .catch(() => {
          this.refreshing = false;
          this.loading = false;
          this.finished = true;
        });
    },
    radioChange(val) {
      this.listQuery.status = Number(val);
      this.onRefresh();
    },
    onTabClick(name) {
      console.log(name);
      if (name === "2") {
        this.getList2();
      }
    },
    getList2() {
      API.getTeamProductList().then((res) => {
        let list = res.data || [];
        // updatePermission：0 可改 1 不可改
        this.headList = list.map((item) => {
          return {
            ...item,
            userDiscountAmount: item.userDiscountAmount * 100,
            childUserDiscountAmount: "",
            checked: true,
          };
        });
        this.oldHeadList = JSON.parse(JSON.stringify(this.headList));
        this.copyHeadList = JSON.parse(JSON.stringify(this.headList));
      });
    },
    onRefresh() {
      //下拉刷星
      // 清空列表数据

      this.finished = false;
      this.refreshing = true;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.listQuery.page = 1;
      this.teamList = [];
      this.getInit(1);
    },
    onLoad() {
      if (this.refreshing) {
        this.teamList = [];
        this.refreshing = false;
      }
      console.log("onLoad");
      this.loading = true;
      this.getInit();
    },
    onSeach(val) {
      //搜索 名称/账户
      if (val.trim()) {
        this.listQuery.keyword = val;
      } else {
        this.$delete(this.listQuery, "keyword");
      }

      this.onRefresh();
    },
    onSeach2() {
      //批量设置搜索
      const reg = new RegExp(this.seachValue2);
      var newarray = [];
      if (this.seachValue2.trim()) {
        for (let i = 0; i < this.headList.length; i++) {
          const label = this.headList[i].discountName;
          if (reg.test(label)) {
            newarray.push(this.headList[i]);
          }
        }
        this.headList = newarray;
      } else {
        this.headList = this.copyHeadList;
      }
    },
    onSwitchChange(val, item) {
      let obj = {
        childUserId: item.userId,
        status: val ? 0 : 1,
      };
      API.getTeanmChildStatus(obj).then((res) => {
        this.teamList.forEach((v) => {
          if (item.userId === v.userId) {
            item.status = obj.status;
          }
        });
        Toast("修改状态成功");
      });
    },
    editDiscount(item) {
      //修改折扣
      this.$router.push({
        path: "/teamDis",
        query: {
          userId: item.userId,
          nickname: item.nickname,
          status: item.status,
          userLevel: item.userLevel,
        },
      });
    },
    editPaw(item) {
      //修改棉麻
      this.userId = item.userId || "";
      this.reNewP = "";
      this.pwdShow = true;
      this.userTitle = item.username || "";
    },
    onPwdSubmit(values) {
      if (!values.reNewP.trim()) {
        Toast("请输入密码");
        return;
      }
      API.getChilderPwd({
        userId: this.userId,
        p: this.reNewP,
      }).then((res) => {
        Toast("修改密码成功");
        this.reNewP = "";
        this.userId = "";
        this.userTitle = "";
        this.pwdShow = false;
      });
    },
    onPwdClose() {
      console.log("onPwdClose");
      // this.reNewP = "";
      // this.userId = "";
      // this.userTitle = "";
      // this.pwdShow = false;
    },
    tagCLick(index) {
      this.tagIndex = index;
      if (index === 1) {
        this.head = this.head2;
      } else {
        this.head = this.head1;
      }
    },
    onInput(val, item) {
      if (Number(val) > Number(item.userDiscountAmount)) {
        Toast("代理折扣不能高于我的折扣");
      }
    },
    onHeadhSwitchChange(val, item) {
      //修改状态
    },
    onSaveClick() {
      //单个保存
    },
    saveClick() {
      let list = [];
      let map = {};

      // for (let v of this.oldHeadList) {
      //   map[v.discountConfigId] = v;
      // }

      // for (let v of this.headList) {
      //   let value = map[v.discountConfigId].childUserDiscountAmount;

      //   if (!value) {
      //     list.push({
      //       discountConfigId: v.discountConfigId,
      //       status: v.checked ? 0 : 1,
      //       childUserDiscount: Number(v.childUserDiscountAmount) / 100,
      //       userDiscountAmount: Number(v.userDiscountAmount) / 100,
      //     });
      //   } else {
      //     if (
      //       Number(value) !== Number(v.childUserDiscountAmount) ||
      //       map[v.discountConfigId].checked !== v.checked
      //     ) {
      //       list.push({
      //         discountConfigId: v.discountConfigId,
      //         status: v.checked ? 0 : 1,
      //         childUserDiscount: Number(v.childUserDiscountAmount) / 100,
      //         userDiscountAmount: Number(v.userDiscountAmount) / 100,
      //       });
      //     }
      //   }
      // }

      for (let v of this.headList) {
        if (!v.childUserDiscountAmount) {
          list.push({
            discountConfigId: v.discountConfigId,
            status: v.checked ? 0 : 1,
            childUserDiscount: null,
            userDiscountAmount: Number(v.userDiscountAmount) / 100,
          });
        } else {
          list.push({
            discountConfigId: v.discountConfigId,
            status: v.checked ? 0 : 1,
            childUserDiscount: Number(v.childUserDiscountAmount) / 100,
            userDiscountAmount: Number(v.userDiscountAmount) / 100,
          });
        }
      }

      if (list.length === 0) {
        return;
      }
      //批量保存
      let flag = true;

      for (let item of list) {
        if (item.childUserDiscount > item.userDiscountAmount) {
          flag = false;
          Toast("代理折扣不能高于我的折扣");
          break;
        }
      }

      if (flag) {
        //排除没有修改过的数据
        let arr = list.map((v) => {
          return {
            discountConfigId: v.discountConfigId,
            status: v.status,
            childUserDiscount: v.childUserDiscount,
          };
        });
        // Toast.loading({
        //   message: "加载中...",
        //   forbidClick: true,
        //   duration: 0,
        // });
        API.postTeamChildBatchUpdata({ data: arr }).then((res) => {
          // Toast.clear();
          Toast("批量设置成功");
          setTimeout(() => {
            this.getList2();
          }, 1000);
        });
      }
    },
    onRight() {
      //添加账号
      this.$router.push("/addAccount");
    },
  },
};
</script>
<style  lang='scss'>
.team2-list-dic {
  .tem2-input1 input {
    text-align: center;
  }
}
</style>
<style lang='scss' scoped>
@import "../../styles/variables.scss";
.container {
  box-sizing: border-box;
}
.team-cen {
  .van-search__content {
    background-color: #fff;
  }
}
.team-sta-cen {
  padding: 0 14px;
  margin-top: 10px;
  .van-cell {
    border-radius: 20px;
  }
}
.team1,
.team2 {
  padding: 4px 14px 14px 14px;
  box-sizing: border-box;

  color: #3e3e3e;
  font-size: 16px;
}
.teamc {
  background-color: #fff;
  margin-bottom: 14px;
  border-radius: 6px;
  padding: 14px;
}
.team-t,
.team-h,
.team-f {
  width: 100%;
  display: flex;
}
.team-tr {
  flex: 1;
  text-align: right;
}
.team-f {
  font-size: 14px;
  text-align: center;
}
.team-item {
  flex: 1;
  font-size: 12px;
}
.team-item span {
  color: $redColor;
}
.team-h {
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 20px;
  font-size: 12px;
}
.team-hr {
  width: 100px;
  text-align: right;
}
.team-hl-img {
  width: 40px;
  height: 40px;
  background-color: #d8d8d8;
  border-radius: 40px;
  text-align: center;
  line-height: 40px;
}
.team-hl {
  flex: 1;
  display: flex;
}

.team-hl-tit {
  font-size: 12px;
  padding-left: 10px;
}

.team2-h,
.team2-head,
.team2-list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.team2-ht {
  flex: 1;
  height: 45px;
  line-height: 45px;
  color: #717171;
  background: #e3e3e3;
  font-size: 14px;
}
.htActive {
  background-color: #e72d94;
  color: #fff;
}
.team2-th {
  flex: 1;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #e5e5e5;
}
.team2-list {
  height: 45px;
  // line-height: 45px;
  border-bottom: 1px solid #e5e5e5;
}
.team2-list > div {
  flex: 1;
  font-size: 12px;
}
.team2-list-def > span {
  background-color: #b6c1fa;
  color: #fff;
  display: inline-block;
  min-width: 40px;
  padding: 6px 15px;
  line-height: 20px;
}
.team2-list-dic .van-cell {
  padding: 4px 10px;
  font-size: 14px;
}
.team2-list-btn .van-button {
  padding-left: 16px;
  padding-right: 16px;
}
.team2-btn {
  margin-top: 30px;
  text-align: center;
  .van-button {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.wrappergg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.blockPwd {
  width: 100%;
  border-radius: 10px;
  .box {
    width: 100%;
    background: #fff;
    padding: 15px 0;

    .box-title {
      color: $textColor;
      font-size: 24px;
    }
    .user-btn {
      text-align: center;
      padding: 15px;
    }
  }
}
.myZindex {
  z-index: 110;
}
</style>
